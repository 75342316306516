'use client'

import { ClipLoader } from 'react-spinners'

const Loading = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <ClipLoader color="#000" />
    </div>
  )
}

export default Loading
